<template>
  <PageContentLayoutOnly :aria-label="$t('complianceViolations.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import SimpleButton from '@/components/buttons/Simple'
import { parseDate } from '@/utilities/Date/parse'

export default {
  name: 'Compliance',
  components: { PageContentLayoutOnly, ModernTable },

  data: () => ({
    isDebug: true,
    loading: true,
    selectedRow: null,

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },

    region: {},
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'id',
        label: 'ID',
        width: '5%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'violationType',
        label: 'Type',
        width: '25%',
        sortable: true,
        searchable: true
      },
      {
        field: 'owner',
        label: 'Owner',
        width: '20%',
        sortable: true,
        searchable: true
      },
      {
        field: 'status',
        label: 'Status',
        width: '1%',
        sortable: true,
        searchable: true
      },
      {
        field: 'occurrences',
        label: 'Occurrences',
        width: '1%',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'date',
        label: 'Date',
        width: '10%',
        date: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'Details',
        label: 'Actions',
        width: '5%'
      }
    ]
  }),

  methods: {
    async getViolations() {
      const ownerID = _get(this, 'unit.primaryOwner.ownerID', null)

      if (typeof ownerID === 'string' || typeof ownerID === 'number') {
        await this.$store.dispatch('violations/getViolations', {
          ownerID: _get(this, 'unit.primaryOwner.ownerID', null)
        })
      }

      this.loading = false
    },
    determineRows: function() {
      let list = _get(this, ['$store', 'getters', 'violations/violationsList'], [])
      if (!Array.isArray(list)) {
        return
      }

      list = _orderBy(list, 'createdDate', 'desc')

      const dateFormat = 'LL'
      var rows = list.map(entry => {
        const id = _get(entry, 'violationID', '')

        const isClosed = _get(entry, 'isClosed', null)
        let status = ''
        if (isClosed === true) {
          status = 'closed'
        } else if (isClosed === false) {
          status = 'open'
        }

        return {
          id,
          violationType: _get(entry, 'violationTypeName', ''),
          owner: _get(entry, 'ownerName', ''),
          status,
          occurrences: _get(entry, 'violationOccurrenceCount', 0),
          date: {
            date: parseDate(_get(entry, 'createdDate', ''), 'LL'),
            format: dateFormat
          },
          details: {
            component: SimpleButton,
            props: {
              to: {
                path: `/violationtabs/${id}/violationDetails#tab`,
                params: {
                  id
                }
              },
              text: 'Details'
            }
          }
        }
      })

      this.rows = rows
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    })
  },

  watch: {
    unit: 'getViolations'
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'violations/violationsList') {
        this.determineRows()
        this.loading = false
      }
    })

    this.getViolations()

    this.loading = false
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  i18n: {
    messages: {
      en: { complianceViolations: { title: 'Compliance Violations' } }
    }
  }
}
</script>

<style></style>
